import MembershipService from './membership.service'

const membershipService = MembershipService()

export const getMembershipList = async store => {
  const state = store.state
  const { currentGlobalProgramId } = state.Program
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const list = await membershipService.getMembershipList(
      currentGlobalProgramId,
    )
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        memberships: list,
        currentTab: 'view',
        membershipToEdit: undefined,
      },
      Member: {
        ...prevState.Member,
        email: '',
      }
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const getPossibleMembershipUpgradeSources = async (
  store,
  { courseId, membershipId },
) => {
  const state = store.state
  const { currentGlobalProgramId } = state.Program
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const list = await membershipService.getPossibleMembershipUpgradeSources(
      currentGlobalProgramId,
      courseId,
      membershipId,
    )
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        possibleMembershipUpgradeSources: list,
      },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const createMembership = async (store, membershipToCreate) => {

  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  membershipToCreate.billingPlans.forEach((billingPlan) => {
    if ((billingPlan.recipNNBillPlanId == "" || billingPlan.recipNNBillPlanId == undefined || billingPlan.recipNNBillPlanId == null)) {
      if (billingPlan.isSGReciprocityAdded === true && billingPlan.recipNNEnabled === false) {
        billingPlan.recipNNEnabled = true;
      }
      else if (billingPlan.isSGReciprocityAdded === false && billingPlan.recipNNEnabled === true) {
        billingPlan.recipNNEnabled = false;
      }
    }
  })

  try {
    await membershipService.create(membershipToCreate)
    decrementLoading(store)
    getMembershipList(store).then(() => {
      showNotification({
        type: 'success',
        message: 'Membership created successfully!',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Membership creation failed!',
    })
    throw error
  }
}

export const updateMembership = async (store, membershipToUpdate) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  membershipToUpdate.billingPlans.forEach((billingPlan) => {
    if ((billingPlan.recipNNBillPlanId == "" || billingPlan.recipNNBillPlanId == undefined || billingPlan.recipNNBillPlanId == null)) {
      if (billingPlan.isSGReciprocityAdded === true && billingPlan.recipNNEnabled === false) {
        billingPlan.recipNNEnabled = true;
      }
      else if (billingPlan.isSGReciprocityAdded === false && billingPlan.recipNNEnabled === true) {
        billingPlan.recipNNEnabled = false;
      }
    }
  })

  try {
    await membershipService.update(membershipToUpdate)
    decrementLoading(store)
    getMembershipList(store).then(() => {
      showNotification({
        type: 'success',
        message: 'Membership updated successfully',
      })
    })
  } catch (error) {
    let message = null
    if (error.response) {
      if (error.response.data.errors) {
        let messageArray = []
        for (const property in error.response.data.errors) {
          messageArray = messageArray.concat(
            error.response.data.errors[property],
          )
        }
        message = messageArray.join(',')
      }
    }
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: message || 'Membership update failed!',
    })
    throw error
  }
}

//1419
export const updateBillingPlan = async (store, updatedBillingPlan) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    await membershipService.updateBillingPlan(updatedBillingPlan)
    decrementLoading(store)

    showNotification({
      type: 'success',
      message: 'BillingPlan updated successfully',
    })

  } catch (error) {
    let message = null
    if (error.response) {
      if (error.response.data.errors) {
        let messageArray = []
        for (const property in error.response.data.errors) {
          messageArray = messageArray.concat(
            error.response.data.errors[property],
          )
        }
        message = messageArray.join(',')
      }
    }
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: message || 'BillingPlan update failed!',
    })
    throw error
  }
}
//1419

export const updateMembershipReciprocities = async (
  store,
  { membershipId, reciprocities },
) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    await membershipService.updateWithReciprocities(membershipId, reciprocities)
    decrementLoading(store)

    clearMembershipToEditReciprocity(store)
  } catch (error) {
    let message = null
    if (error.response) {
      if (error.response.data.errors) {
        let messageArray = []
        for (const property in error.response.data.errors) {
          messageArray = messageArray.concat(
            error.response.data.errors[property],
          )
        }
        message = messageArray.join(',')
      }
    }
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: message || 'Membership update failed!',
    })
    throw error
  }
}

export const selectMembershipToEdit = (store, membershipToEditId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  membershipService
    .getMembershipById(membershipToEditId)
    .then(membershipToEdit => {
      store.setState(prevState => ({
        ...prevState,
        Membership: {
          ...prevState.Membership,
          membershipToEdit,
          currentTab: 'createOrEdit',
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading()
      throw error
    })
}

export const selectMembershipToEditReciprocity = async (store, membership) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const { getPossibleProgramCourses } = state.Program.actions
  const { providerId } = state.Program.globalProgram
  try {
    incrementLoading(store)
    await getPossibleProgramCourses({ providerId })
    const membershipReciprocityCourses = await membershipService.getMembershipReciprocities(
      membership.id,
    )

    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        membershipReciprocityCourses,
        membershipToEditReciprocity: membership,
      },
    }))
    decrementLoading(store)
  } catch (error) {
    decrementLoading()
    throw error
  }
}

export const selectMembershipToView = (store, membershipToViewId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  membershipService
    .getMembershipById(membershipToViewId)
    .then(membershipToView => {
      store.setState(prevState => ({
        ...prevState,
        Membership: {
          ...prevState.Membership,
          membershipToView,
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading()
      throw error
    })
}

export const clearMembershipToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    Membership: {
      ...prevState.Membership,
      membershipToEdit: undefined,
      currentTab: 'view',
    },
  }))
}

export const clearMembershipToEditReciprocity = store => {
  store.setState(prevState => ({
    ...prevState,
    Membership: {
      ...prevState.Membership,
      membershipReciprocityCourses: undefined,
      membershipToEditReciprocity: undefined,
    },
  }))
}

export const clearMembershipToView = store => {
  store.setState(prevState => ({
    ...prevState,
    Membership: {
      ...prevState.Membership,
      membershipToView: undefined,
      possibleMembershipUpgradeSources: [],
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  if (tabName === 'view') {
    clearMembershipToEdit(store)
  } else {
    store.setState(prevState => ({
      ...prevState,
      Membership: { ...prevState.Membership, currentTab: tabName },
    }))
  }
}

export const getPossibleReciprocalCourses = async (store) => {
  const possibleReciprocalCourses = await membershipService.getPossibleReciprocalCourses()

  store.setState(prevState => ({
    ...prevState,
    Membership: {
      ...prevState.Membership,
      possibleReciprocalCourses
    }
  }))
}

export const getPlanEffectiveDates = async (store, { frequencyId, billingDateId, billingMonth, billingDay, minimumBillingCyclesToCancel }) => {
  const planEffectiveDates = await membershipService.getPlanEffectiveDates(frequencyId, billingDateId, billingMonth, billingDay, minimumBillingCyclesToCancel)

  store.setState(prevState => ({
    ...prevState,
    Membership: {
      ...prevState.Membership,
      planEffectiveDates
    }
  }))
}

export const checkPOSIntegration = async (store, currentGlobalProgramId) => {

  try {
    const isPOSIntegration = await membershipService.checkPOSIntegration(currentGlobalProgramId)
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        isPOSIntegrated: isPOSIntegration
      }
    }))
    return isPOSIntegration;
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        isPOSIntegrated: false
      }
    }))
    return false;
  }
}

export const GetPOSFieldData = async (store, { providerId, courseId }) => {
  console.log(courseId)
  const posFieldData = await membershipService.GetPOSFieldData(providerId, courseId)
  store.setState(prevState => ({
    ...prevState,
    Membership: {
      ...prevState.Membership,
      posFieldData: posFieldData
    }
  }))

}

export const getSGNetworkPrograms = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  try {
    const list = await membershipService.getSGNetworkPrograms()
    const programList=[...list]
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        availableSGNetworkPrograms: programList
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const updateActivateBenefitsRNN = async (store, { billingPlanId, rnnBillingPlanId }) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await membershipService.updateActivateBenefitsRNN(billingPlanId, rnnBillingPlanId)
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: 'Reciprocal Benefits updated successfully',
    })
  } catch (error) {
    let message = null
    if (error.response) {
      if (error.response.data.errors) {
        let messageArray = []
        for (const property in error.response.data.errors) {
          messageArray = messageArray.concat(
            error.response.data.errors[property],
          )
        }
        message = messageArray.join(',')
      }
    }
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: message || 'Reciprocal Benefits update failed!',
    })
    throw error
  }
}

export const getSGNetworkMembershipsByProgram = async (store, program) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  const {programId} = program ? program : ''
  incrementLoading(store)
  try {
    const list = await membershipService.getSGNetworkMembershipsByProgram(programId)
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Membership,
        availableSGNetworkMemberships: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getSGNetworkBillingPlans = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await membershipService.getSGNetworkBillingPlans(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      Membership: {
        ...prevState.Dashboard,
        availableSGNetworkBillingPlans: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}